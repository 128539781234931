#json-schema-preview .form-group.field {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 5px;
    padding-top: 2px;
}

#json-schema-preview .checkbox.disabled > label > label {
    display: none;
}

#json-schema-preview .form-group.field.field-object > span {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: var(--kt-text-gray-700);
}

#json-schema-preview .form-group.field > .field-description {
    display: none;
}

#json-schema-preview .form-group.field.field-object {
    display: flex;
    width: 100%;
}

#json-schema-preview .form-group.field.field-boolean {
    margin-left: 10px;
    display: none;
}

#json-schema-preview .row:has(> .form-group.field.field-string) {
    width: 105%;
}

#json-schema-preview .form-group.field.field-boolean:has(.checkbox > label > input[type=checkbox]:checked) {
    display: block;
}

#json-schema-preview .checkbox > label > input[type=checkbox]:checked {
    display: none;
}

#json-schema-preview .form-group.field.field-object > span {
    display: flex;
    width: 60%;
}

#json-schema-preview .form-group.field.field-boolean {
    padding: 12px;
    font-size: 1.075rem;
    background-color: transparent;
    font-weight: 400;
}

#json-schema-preview .row:has(> .form-group.field.field-boolean) {
    display: flex;
    width: 40%;
}

#json-schema-preview .control-label {
    padding-right: 10px;
    display: flex;
    width: 60%;
    color: var(--kt-text-muted);
    font-weight: 500;
    align-items: center;
    white-space: break-spaces;
    padding-left: 7px;
}

#json-schema-preview .form-control {
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    font-size: 1.075rem;
    background-color: transparent;
    border: 0;
    font-weight: 400;
    white-space: break-spaces;
}

#json-schema-preview .textLabel {
    padding: 12px;
    width: 40%;
    display: block;
    flex-wrap: wrap;
    font-size: 1.075rem;
    background-color: transparent;
    border: 0;
    font-weight: 400;
    text-align: justify;
    max-width: 400px;
}

#json-schema-preview .form-group.field.field-object {
    width: 100%;
}

#json-schema-preview .form-group.field.field-object > div {
    width: 100%;
}

#json-schema-preview span.required {
    color: transparent;
}

#json-schema-preview span.required::after {
    color: transparent;
}

#json-schema-preview .checkbox > label > input[type=checkbox] {
    background-size: 60% 60%;
}

#json-schema-preview .checkbox > label > input {
    border: 0;
    background-color: var(--kt-form-check-input-bg-solid);
}

#json-schema-preview .checkbox > label > input {
    margin: 0;
    float: none;
    flex-shrink: 0;
}

#json-schema-preview .checkbox > label > input[type=checkbox] {
    border-radius: 0.45em;
    margin-right: 5px;
}

#json-schema-preview .checkbox > label > input {
    background-color: var(--kt-form-check-input-bg);
    border: var(--kt-form-check-input-border);
}

#json-schema-preview .checkbox > label > input {
    width: 1.75rem;
    height: 1.75rem;
    margin-top: -0.125rem;
    vertical-align: top;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #e4e6ef;
    appearance: none;
}

#json-schema-preview .checkbox > label > input:checked {
    background-color: var(--kt-form-check-input-checked-bg-color-solid);
}

#json-schema-preview .checkbox > label > input[type=checkbox] {
    background-size: 60% 60%;
}

#json-schema-preview .checkbox > label > input {
    border: 0;
    background-color: var(--kt-form-check-input-bg-solid);
}

#json-schema-preview .checkbox > label > input:checked[type=checkbox] {
    background-image: var(--kt-form-check-input-checked-bg-image);
}

#json-schema-preview .checkbox > label > input:checked[type=checkbox] {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 13 11%27 width=%2713%27 height=%2711%27 fill=%27none%27%3e%3cpath d=%27M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z%27 fill=%27%23ffffff%27/%3e%3c/svg%3e');
}

#json-schema-preview .checkbox > label > input:checked {
    background-color: var(--kt-form-check-input-checked-bg-color);
    border-color: var(--kt-form-check-input-checked-border-color);
}

#json-schema-preview .checkbox > label > input:checked {
    background-color: #009ef7;
    border-color: #009ef7;
}

#json-schema-preview .checkbox > label > input[type=checkbox] {
    border-radius: 0.45em;
}

#json-schema-preview .checkbox > label > input:disabled {
    opacity: var(--kt-form-check-input-disabled-opacity);
}

#json-schema-preview .checkbox > label > input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
}

.dzu-previewContainer {
    width: 100% !important;
    border-bottom: none !important;
    display: flex !important;
    flex-wrap: wrap !important;
}

.text-justify {
    text-align: justify;
}

@media(max-width:400px) {
    #json-schema-preview .form-group.field {
        padding-bottom: 10px;
    }   
}


