//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base';
@import 'layout/layout';

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

// Custom react-select
.select__control {
  min-height: 43.6px !important;
}

#react-select-4-listbox {
  position: relative !important;
  z-index: 1000 !important;
}

.css-b62m3t-container {
  background-color: var(--kt-input-bg);
  position: relative !important;
  //z-index: 109 !important;
  color: var(--kt-input-color);
}

.css-1s2u09g-control {
  background-color: var(--kt-input-solid-bg) !important;
  border-color: var(--kt-input-solid-bg);
  border: 0 !important;
  padding: 0.2rem !important;
  border-radius: 0.4rem !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  color: var(--kt-input-solid-color);
  transition: color 0.2s ease;
  min-height: 43.6px !important;
}

.css-1pahdxg-control {
  background-color: var(--kt-input-solid-bg) !important;
  border: 1px solid var(--kt-input-border-color);
  box-shadow: none !important;
  padding: 2px !important;
  border-radius: 0.475rem !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  color: var(--kt-input-solid-color) !important;
}

.css-yt9ioa-option {
  background-color: var(--kt-input-solid-bg) !important
}

.css-1n7v3ny-option {
  background-color: var(--kt-primary) !important;
  color: var(--kt-primary-inverse) !important;
}

.css-1s2u09g-control:hover {
  background-color: var(--kt-input-solid-bg) !important;
  border-color: var(--kt-input-solid-bg);
}

.select__control {
  border: 0 !important;
}

.css-1pahdxg-control {
  border: 0 !important;
}

.select__menu {
  background-color: var(--kt-input-bg) !important;
}

#react-select-3-listbox {
  background-color: var(--kt-input-bg) !important;
}

.select__input-container > input {
  color: var(--kt-input-solid-color) !important;
}

#react-select-3-input {
  color: var(--kt-input-solid-color) !important;
}

.select__single-value {
  color: var(--kt-input-solid-color) !important;
}

#react-select-6-listbox {
  z-index: 999999 !important;
}

.select__menu-portal {
  z-index: 9999 !important;
}

.select__option--is-selected {
  background-color: var(--kt-primary) !important;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 43%, var(--kt-danger) 45%, var(--kt-danger) 55%, rgba(0, 0, 0, 0) 57%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(135deg, transparent 0%, transparent 43%, var(--kt-danger) 45%, var(--kt-danger) 55%, transparent 57%, transparent 100%);
  cursor: pointer;
}

.notification-table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.contract-table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.notification-row > td:nth-child(1) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.notification-row > td:nth-last-child(1) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.translate-comment {
  transform: translate(-100%, -50%) !important;
}

.dzu-previewFileName {
  color: var(--kt-form-label-color) !important;
  font-weight: bold !important;
}

// progress bar
progress {
  accent-color: var(--kt-primary);
}

// loading
.loading-back {
  background-color: #cccccc;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 5px;
}

.loading-dot {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: 2.5px;
  margin-top: 2.5px;
  animation: loading-fill 1s ease-in-out infinite;
}

.loading-one {
  animation-delay: 0.3s;
  background-color: white;
}

.loading-two {
  animation-delay: 0.1s;
  background-color: white;
}

.loading-three {
  animation-delay: -0.15s;
  background-color: white;
}

.loading-four {
  animation-delay: -0.3s;
  background-color: white;
}

@keyframes loading-fill {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

// simple-loading
.simple-loading-back {
  background-color: #cccccc;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 2.5px;
}

.simple-loading-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 2.5px;
  margin-top: 2.5px;
  animation: simple-loading-fill 1s ease-in-out infinite;
}

.simple-loading-one {
  animation-delay: 0.3s;
  background-color: white;
}

.simple-loading-two {
  animation-delay: 0.1s;
  background-color: white;
}

.simple-loading-three {
  animation-delay: -0.15s;
  background-color: white;
}

.simple-loading-four {
  animation-delay: -0.3s;
  background-color: white;
}

@keyframes simple-loading-fill {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.year-select > .dropdown-toggle:after {
  display: none !important;
}