#json-schema-form .form-group {
    margin-bottom: 45px;
    margin-top: 7px;
    padding: 0;
    padding-left: 4px;
    padding-right: 4px;
}

#json-schema-form .input-group {
    padding-right: 10px;
}

#json-schema-form .row {
    margin: 0;
    padding: 0;
}

#json-schema-form .dzu-dropzone {
    display: none;
}

#json-schema-form label {
    color: var(--kt-form-label-color);
    font-weight: bold;
    padding: 10px;
    padding-left: 0;
}

#json-schema-form .radio {
    display: flex;
}

#json-schema-form .radio.disabled > label > span > input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23ffffff%27/%3e%3c/svg%3e");
}

#json-schema-form .radio.disabled > label > span > input:checked {
    background-color: #009ef7;
    border-color: #009ef7;
}

#json-schema-form .radio.disabled > label > span > input[type=radio] {
    border-radius: 50%;
}

#json-schema-form .radio.disabled > label > span > input {
    background-color: var(--kt-form-check-input-bg);
    border: var(--kt-form-check-input-border);
}

#json-schema-form .radio.disabled > label > span > input {
    width: 1.75rem;
    height: 1.75rem;
    margin-top: -0.125rem;
    vertical-align: top;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #e4e6ef;
    appearance: none;
}

#json-schema-form .radio > label > span > input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23ffffff%27/%3e%3c/svg%3e");
}

#json-schema-form .radio > label > span > input:checked {
    background-color: #009ef7;
    border-color: #009ef7;
}

#json-schema-form .radio > label > span > input[type=radio] {
    border-radius: 50%;
}

#json-schema-form .radio > label {
    cursor: pointer;
}

#json-schema-form .radio > label > span {
    cursor: pointer;
}

#json-schema-form .radio > label > span > input[type=radio] {
    cursor: pointer;
}

#json-schema-form .radio.disabled > label > span > input {
    background-color: var(--kt-form-check-input-bg);
    border: var(--kt-form-check-input-border);
}

#json-schema-form .radio > label > span > input {
    width: 1.75rem;
    height: 1.75rem;
    margin-top: -0.125rem;
    vertical-align: top;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #e4e6ef;
    appearance: none;
}

#json-schema-form .field-radio-group {
    display: flex;
}

#json-schema-form .radio > input {
    margin: 10px;
}

#json-schema-form .radio > label > span {
     display: flex;
}


#json-schema-form .btn.btn-info {
    background-color: var(--kt-primary);
}

#json-schema-form .btn.btn-info:hover {
    background-color: var(--kt-primary);
}

#json-schema-form input[type='radio'] {
    margin: 10px;
}

#json-schema-form span {
    color: var(--kt-form-label-color);
    font-weight: bold;
}

#json-schema-form .form-control {
    border-color: var(--kt-input-solid-bg);
    background-color: var(--kt-input-solid-bg);
}

#json-schema-form .form-control:focus {
    border-color: var(--kt-input-solid-bg);
    background-color: var(--kt-input-solid-bg-focus);
    transition: color 0.2s ease;
}

#json-schema-form .control-label {
    color: var(--kt-form-label-color);
    font-weight: bold;
}

#json-schema-form span.required {
    margin-left: 2px;
    color: var(--kt-danger);
}

#json-schema-form span.required::after {
    color: transparent;
}

#json-schema-form .text-danger::after {
    color: transparent;
}

#json-schema-form .panel.panel-danger.errors {
    display: none;
}

#json-schema-form .checkbox > label > input[type=checkbox] {
    margin-bottom: 5px;
    background-size: 60% 60%;
}

#json-schema-form .checkbox > label > input {
    border: 0;
    background-color: var(--kt-form-check-input-bg-solid);
}

#json-schema-form .checkbox > label > input {
    margin: 0;
    float: none;
    flex-shrink: 0;
}

#json-schema-form .checkbox > label > input[type=checkbox] {
    border-radius: 0.45em;
    margin-right: 5px;
}

#json-schema-form .checkbox > label > input {
    background-color: var(--kt-form-check-input-bg);
    border: var(--kt-form-check-input-border);
}

#json-schema-form .checkbox > label > input {
    width: 1.75rem;
    height: 1.75rem;
    margin-top: -0.125rem;
    vertical-align: top;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #e4e6ef;
    appearance: none;
}

#json-schema-form .checkbox > label > input:checked {
    background-color: var(--kt-form-check-input-checked-bg-color-solid);
}

#json-schema-form .checkbox > label > input[type=checkbox] {
    background-size: 60% 60%;
}

#json-schema-form .checkbox > label > input {
    border: 0;
    background-color: var(--kt-form-check-input-bg-solid);
}

#json-schema-form .checkbox > label > input:checked[type=checkbox] {
    background-image: var(--kt-form-check-input-checked-bg-image);
}

#json-schema-form .checkbox > label > input:checked[type=checkbox] {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 13 11%27 width=%2713%27 height=%2711%27 fill=%27none%27%3e%3cpath d=%27M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z%27 fill=%27%23ffffff%27/%3e%3c/svg%3e');
}

#json-schema-form .checkbox > label > input:checked {
    background-color: var(--kt-form-check-input-checked-bg-color);
    border-color: var(--kt-form-check-input-checked-border-color);
}

#json-schema-form .checkbox > label > input:checked {
    background-color: #009ef7;
    border-color: #009ef7;
}

#json-schema-form .checkbox > label > input[type=checkbox] {
    border-radius: 0.45em;
}

#json-schema-form .checkbox > label > input:disabled {
    opacity: var(--kt-form-check-input-disabled-opacity);
}

#json-schema-form .checkbox > label > input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
}

#json-schema-form {
    white-space: pre-wrap;
    vertical-align: bottom;
}

#json-schema-form .field-object {
    margin: 0;
}

#json-schema-form .field-object > .mt-2 {
    font-size: 13px;
}

