html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  .intro {
    max-width: 1280px;
    margin: 1em auto;
  }
  .table-scroll {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    height: 430px;
  }
  .table-scroll table {
    width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
  }
  .table-wrap {
    position: relative;
  }
  .table-scroll th,
  .table-scroll td {
    padding: 5px 10px;
    vertical-align: top;
  }
  .table-scroll thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--kt-header-default-bg-color);
    justify-content: center;
  }
  /* safari and ios need the tfoot itself to be position:sticky also */
  .table-scroll tfoot,
  .table-scroll tfoot th,
  .table-scroll tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: var(--kt-header-default-bg-color);
    color: #fff;
    z-index: 1;
  }
  
  th:first-child {
    position: -webkit-sticky;
    left: 0;
    z-index: 2;
  }
  thead th:first-child,
  tfoot th:first-child {
    z-index: 5;
  }
  